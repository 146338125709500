import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicationPaths } from 'src/app/core/constants/app.constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { ValidatorHandlers } from 'src/app/core/utilities/validators.handler';
import { AlertService } from 'src/app/shared/alerts';

@Component({
  selector: 'app-reset-code',
  templateUrl: './reset-code.component.html',
  styleUrls: ['./reset-code.component.scss']
})
export class ResetCodeComponent implements OnInit {
  applicationPaths = ApplicationPaths;
  emailSubscription: Subscription;
  loading = false;

  emailAddressSent: string;
  resetForm: FormGroup;

  buildForm(): void {
    this.resetForm = new FormGroup({
      newPassword: new FormControl(null, [Validators.required, Validators.minLength(8) ] ),
      confirmPassword: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      resetCode: new FormControl(null, [Validators.required]),
    }, ValidatorHandlers.passwordMatchValidator);
  }

  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

  constructor(
    private readonly alertService: AlertService,
    private readonly authService: AuthService,
    private readonly router: Router
  ) { }

  ngOnInit() {
    this.emailSubscription = this.authService.resetEmail.subscribe(
      (email: string) => {
        if (email) {
          this.emailAddressSent = email;
        } else {
          this.navigateBack();
        }
      }
    );

    this.buildForm()
  }

  navigateBack(){
    this.router.navigate([this.applicationPaths.ResetPassword]);
  }

  postResetCode(){
    this.loading = true;
    const payload = this.resetForm.value;
    payload.email = this.emailAddressSent;
    payload.password = this.resetForm.controls['newPassword'].value;

    this.authService.resetCode(payload).subscribe(
      (_) => {
        this.loading = false;
        this.alertService.success('Successfully Reset Password', this.options);
        this.router.navigate([this.applicationPaths.Login]);
      }, (error) => {
        this.loading = false;
        this.alertService.error('Please check your entries and try again', this.options);
        this.resetForm.reset();
      }
    )
  }

}
