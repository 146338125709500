import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxsModule } from "@ngxs/store";
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { environment } from "src/environments/environment";
import { AuthRoutingModule } from "./auth-routing.module";
import { LoginComponent } from "./component/login/login.component";
import { ResetPasswordComponent } from "./component/reset-password/reset-password.component";
import { AuthState } from "./state/auth.state";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";
import { SharedModule } from "../shared/shared.module";
import { ResetCodeComponent } from "./component/reset-code/reset-code.component";

@NgModule({
    declarations: [LoginComponent, ResetPasswordComponent, ResetCodeComponent],
      imports: [
        // NgxsModule.forRoot([AuthState], {
        //     developmentMode: !environment.production,
        // }),
        // NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
        // NgxsStoragePluginModule.forRoot({
        //     key: ['auth.token', 'auth.username'],
        // }),
          SharedModule,
          CommonModule,
          AuthRoutingModule,
          ReactiveFormsModule,
          FormsModule,
          ReactiveFormsModule
      ]
  })
  export class AuthModule { }