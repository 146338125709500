import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/core.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { environment } from 'src/environments/environment';
import { AuthState } from './auth/state/auth.state';
import { SharedModule } from './shared/shared.module';
import { ListFilterPipe } from './core/utilities/filterPipe';
import { NgxPrintModule } from 'ngx-print';

@NgModule({
  declarations: [	
    AppComponent,
   ],
  imports: [
    NgxsModule.forRoot([AuthState], {
      developmentMode: !environment.production,
  }),
  NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
  NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth.username'],
  }),
    BrowserModule,
    AppRoutingModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    CoreModule,
    NgxPrintModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
