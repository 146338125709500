<div class="wrapper">
  <app-sidebar></app-sidebar>

  <div id="content" class="px-4">
    <app-navbar></app-navbar>

    <main>
      <alert></alert>
      <router-outlet></router-outlet>
    </main>
    
  </div>
</div>
