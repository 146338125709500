<!-- <app-loader></app-loader> -->
<div class="container-fluid">
  <div class = "contain">
    <alert></alert>
    
    <img class = "position-absolute top-0 start-50 translate-middle top-logo" src="../../../../assets/images/Aglogo.png">
    <!-- <img class = "logo position-absolute top-0 start-50 translate-middle-x" src="../../../../assets/images/Logo.png"> -->
  <div class="card position-absolute bottom-0 start-50 translate-middle-x" style="width: 24rem; height: 28rem;">
    <div class="card-body shadow rounded">
      <div class="position-absolute top-0 start-50 translate-middle rounded-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-person-fill text-white position-absolute top-50 start-50 translate-middle" viewBox="0 0 16 16">
          <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
        </svg>
      </div>
      <main class="form-signin">
        <form [formGroup]="loginForm" (ngSubmit)="login()">
          <h1 class="h3 fw-bold lh-1 text-center">Welcome to APS</h1>
          <p class="text-center">Login to continue</p>
          <div class = "text-set">
          <div class="input-group">
          <label for="inputEmail" class="visually-hidden">Email address</label>
          <span class ="espan">
            <i class="bi bi-person-fill eperson"></i>
          </span>
          <input
            type="email"
            id="inputEmail"
            class="form-control"
            formControlName="email"
            placeholder="Email address"
            required
            
          />
          </div>
          <small id="passwordHelpBlock" class="ms-2 text-muted" *ngIf="emailFormControl.invalid && (emailFormControl.dirty || emailFormControl.touched)"
            >
            <span class="text-danger">Enter a valid email address</span>
            </small>
        </div>
        <div class = "text-set">
          <div class="input-group">
          <label for="inputPassword" class="visually-hidden">Password</label>
          <span class ="espan">
            <i class="bi bi-lock-fill eperson"></i>
          </span>
          <input
            type="password"
            id="inputPassword"
            class="form-control"
            formControlName="password"
            placeholder="Password"
            required
          />
        </div>
        <small id="passwordHelpBlock" class="ms-2 text-muted" *ngIf="passwordFormControl.invalid && (passwordFormControl.dirty || passwordFormControl.touched)"
            >
            <span class="text-danger">Enter a valid password</span>
            </small>
      </div>
        <div class="bottom">
          <button class="w-100 btn btn-lg btn-purple" type="submit" [disabled]="!loginForm.valid">
            LOGIN
            <span class="spinner-grow spinner-grow-sm ms-1 mb-1" role="status" aria-hidden="true" *ngIf="loading"></span>
          </button>
          
          <p class="mt-2 mb-3 forgot text-center" (click)="navigateToReset()">
            Forgot Password?
          </p>
        </div>
        </form>
      </main>
    </div>
  </div>
</div>
</div>
