import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ApiModel } from '../models/api.model';
import { UserProfile } from '../models/user.model';
import { AuthConstants, StatusCodes } from '../constants/app.constants';
import { ErrorHandlers } from '../utilities/error.handlers';
import { LoginModel } from '../models/login.model';
// import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private readonly httpClient: HttpClient,
  ) {}

  resetEmail = new BehaviorSubject<string>(null);

  login(
    username: string,
    password: string
  ): Observable<ApiModel<LoginModel>> {
    return this.httpClient
      .post<ApiModel<LoginModel>>(
        AuthConstants.authUrl,
        {
          username,
          password,
        },
      )
      .pipe(catchError(ErrorHandlers.handleApiError));
  }

  logout():Observable<ApiModel<any>> {
    return this.httpClient.get<ApiModel<any>>(
      AuthConstants.logoutUrl, 
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization:
            'Bearer ' + localStorage.getItem('auth.token').split('"')[1],
        }),
      }
    ).pipe(catchError(ErrorHandlers.handleApiError));
  }

  changePassword(model:any): Observable<ApiModel<any>> {
    return this.httpClient
      .post<ApiModel<any>>(
        `${AuthConstants.changePassword}`,
        model,
        {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + localStorage.getItem('auth.token').split('"')[1],
          }),
        }
        )
      .pipe(catchError(ErrorHandlers.handleApiError));
  }

  resetPassword(model:any): Observable<ApiModel<any>> {
    return this.httpClient
      .get<ApiModel<any>>(
        `${AuthConstants.resetPassword}?email=${model}`
        )
      .pipe(catchError(ErrorHandlers.handleApiError));
  }

  resetCode(model:any): Observable<ApiModel<any>> {
    return this.httpClient
      .post<ApiModel<any>>(
        `${AuthConstants.resetCode}`,
        model
        )
      .pipe(catchError(ErrorHandlers.handleApiError));
  }

}
interface TokenResponse {
  token: string;
}
