import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApplicationPaths } from 'src/app/core/constants/app.constants';
import { AuthService } from 'src/app/core/services/auth.service';
import { AlertService } from 'src/app/shared/alerts';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  applicationPaths = ApplicationPaths;
  emailForm: FormGroup;
  loading = false;

  buildForm(): void {
    this.emailForm = new FormGroup({
      emailAddress: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  options = {
    autoClose: true,
    keepAfterRouteChange: true
};

  constructor(
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  navigateBack(){
    this.router.navigate([this.applicationPaths.Login]);
  }

  postResetPassword(){
    const emailAddress = this.emailForm.controls['emailAddress'].value;
    this.authService.resetEmail.next(emailAddress);
    this.loading = true;

    this.authService.resetPassword(emailAddress).subscribe(
      (_) => {
        this.loading = false;
        this.alertService.success('Please check your email for reset code', this.options);
        this.router.navigate([this.applicationPaths.ResetCode]);
      }, (error) => {
        this.alertService.error(error.toString(), this.options);
        this.loading = false;
      }
    )
  }

}
