<nav class="navbar navbar-expand-lg navbar-light navs">
  <div class="container-fluid navv  d-flex justify-content-between">
    <span class="d-flex flex-row">
    <button type="button" id="sidebarCollapse">
      <i class="bi bi-list"></i>
    </button>

    <p class="text-nowrap d-none d-lg-block">{{todayDate | date: "longDate"}}</p>
  </span>

        <div class="coll">
          <!-- <div class="input-group input-group-alternative mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="search"><i class="bi bi-search eperson"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Search" id="search"
              formControlName="value">
          </div> -->

          <div class="input-group input-group-alternative mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="search"><i class="bi bi-search eperson"></i></span>
            </div>
            <input type="text" class="form-control" placeholder="Search" id="search"
              formControlName="value">
          </div>
        </div>

        <div class = "d-flex">
          <div class = "d-flex pt-3">
          <img src="../../../assets/images/profile.png" class="rounded-circle" height="32" width="32" alt="...">
          <p id="profile">{{fullName}}</p>  
        </div>
              <div class="btn-group dropleft">
                <button type="button" class="btn btn-secondary avatar" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i class="bi bi-chevron-down"></i>
                </button>
                <div class="dropdown-menu mt-5 ml-3">
                  <li><a class="dropdown-item d-flex pt-3" [routerLink]="['/profile']">
                    <img src="../../../assets/images/user 1.png" height="20" width="20" class="mt-1 ms-1" alt="...">
                    <p class="ms-2 mt-1 text-base me-1 drops">Profile</p>
                  </a></li>
                  <li><a class="dropdown-item d-flex" [routerLink]="['/change-password']">
                    <img src="../../../assets/images/padlock 2.png" height="20" width="20" class="mt-1 ms-1" alt="...">
                    <p class="ms-2 mt-1 text-base me-1 drops">Change Password</p>
                  </a></li>
                  <li><a class="dropdown-item d-flex pb-3" (click)="logOut()">
                    <img src="../../../assets/images/logout 1.png" height="20" width="20" class="mt-1 ms-1" alt="...">
                    <p class="ms-2 mt-1 text-base me-1 drops">Logout</p>
                  </a></li>
                </div>
              </div>
            </div> 
    
  </div>
</nav>