import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./component/login/login.component";
import { ResetCodeComponent } from "./component/reset-code/reset-code.component";
import { ResetPasswordComponent } from "./component/reset-password/reset-password.component";

const routes: Routes = [
    { path: '' , component: LoginComponent },
    { path: 'login', component: LoginComponent},
    { path: 'reset-password', component: ResetPasswordComponent},
    { path: 'reset-code', component: ResetCodeComponent},
  ];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
  })
  export class AuthRoutingModule { }