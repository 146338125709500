import { ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts/alerts.component';
import { LoaderComponent } from './loader/loader.component';
import { ListFilterPipe } from '../core/utilities/filterPipe';



@NgModule({
  declarations: [AlertsComponent, LoaderComponent, ListFilterPipe],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [AlertsComponent, LoaderComponent, ListFilterPipe],
})
export class SharedModule {}
