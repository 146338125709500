import { Component, OnInit } from '@angular/core';
import { Router, RouterState, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { Logout } from 'src/app/auth/state/auth.actions';
import { ApplicationPaths, QueryParameterNames } from '../../constants/app.constants';
import { Keys } from '../../constants/key.constants';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  todayNumber: number = Date.now();
  todayDate : Date = new Date();
  todayString : string = new Date().toDateString();
  todayISOString : string = new Date().toISOString();
  fullName: string;

  constructor(
    private readonly router: Router,
    private readonly store: Store
  ) { }

  ngOnInit() {
    var profile = JSON.parse(localStorage.getItem(Keys.PROFILE));
    this.fullName = profile.formatName;
  }

  async logOut(){
    const state: RouterState = this.router.routerState;
    const snapshot: RouterStateSnapshot = state.snapshot;
    await this.router.navigate(ApplicationPaths.LoginPathComponents, {
      queryParams: {
        [QueryParameterNames.ReturnUrl]: snapshot.url
      }
    })
    this.store.dispatch(new Logout()).
      subscribe(
        async(result) => {
        }
      )
    
    
    
  }

}
