import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './auth/component/login/login.component';
import { LayoutComponent } from './core/layout/layout.component';


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/dashboard/dashboard.module')).DashboardModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/transactions/transactions.module')).TransactionsModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/wallet/wallet.module')).WalletModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/payments/payments.module')).PaymentsModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/tracking/tracking.module')).TrackingModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/quotes/quotes.module')).QuotesModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/profile/profile.module')).ProfileModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/change-password/change-password.module')).ChangePasswordModule,
      },
      {
        path: '',
        canActivate: [AuthGuard],
        loadChildren: async () =>
          ( await import('./features/waybill/waybill.module')).WaybillModule,
      },
   ]
  },
  {
    path: 'auth',
    loadChildren: async () =>
      ( await import('./auth/auth.module')).AuthModule
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
