<div class="container-fluid">
  <div class = "contain">
    <alert></alert>
    <img class = "position-absolute top-0 start-50 translate-middle top-logo" src="../../../../assets/images/Aglogo.png">
  <div class="card position-absolute bottom-0 start-50 translate-middle-x" style="width: 24rem; height: 28rem;">
    <div class="card-body shadow rounded">
      <div class="position-absolute top-0 start-50 translate-middle rounded-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-lock-fill text-white position-absolute top-50 start-50 translate-middle" viewBox="0 0 16 16">
          <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/>
        </svg>
      </div>
      <div class = "head">
        <svg xmlns="http://www.w3.org/2000/svg" (click)="navigateBack()" width="28" height="28" fill="currentColor" class="bi bi-chevron-left position-absolute bottom-0 start-0 back" viewBox="0 0 12 12">
          <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
        </svg>

        <h1 class="h3 fw-bold lh-1 text-center">Reset Password</h1>
      </div>
      <main class="form-signin">
        <form [formGroup]="emailForm" (ngSubmit)="postResetPassword()">
          
          
          <div class="input-group">
          <label for="inputEmail" class="visually-hidden">Email address</label>
          <span class ="espan">
            <i class="bi bi-person-fill eperson"></i>
          </span>
          <input
            type="email"
            id="inputEmail"
            class="form-control"
            formControlName="emailAddress"
            placeholder="Phone Number or Email address"
            required
            
          />
          </div>

          <button class="w-100 btn btn-lg btn-purple" type="submit" [disabled]="!emailForm.valid">
            Reset
            <span class="spinner-grow spinner-grow-sm ms-1 mb-1" role="status" aria-hidden="true" *ngIf="loading"></span>
          </button>
          
        </form>
      </main>
    </div>
  </div>
</div>
</div>
