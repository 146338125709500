import { JwtHelperService } from '@auth0/angular-jwt';
import { TokenModel } from '../models/token.model';

export class JwtHelper {
  static getokenData(token: string | null): TokenModel | null {
    if (!token.includes('.')) {
      return null;
    }

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    const expirationDate = helper.getTokenExpirationDate(token);
    const isExpired = helper.isTokenExpired(token);
    const tokenModel: TokenModel = {
      Email: decodedToken.sub,
      ExpireDate: expirationDate,
      IsExp: isExpired,
      Id: decodedToken.Id,
      Code: decodedToken.Code
    };
    return tokenModel;
  }
}
