import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ApplicationPaths } from 'src/app/core/constants/app.constants';
import { LoginModel } from 'src/app/core/models/login.model';
import { AlertService } from 'src/app/shared/alerts';
import { Login } from '../../state/auth.actions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  applicationPaths = ApplicationPaths;
  loading = false;

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });

  options = {
    autoClose: true,
    keepAfterRouteChange: false
};

  constructor(
    private readonly alertService: AlertService,
    private readonly router: Router,
    private readonly store: Store
  ) { }

  ngOnInit() {
  }

  get emailFormControl(){
    return this.loginForm.get('email');
  }

  get passwordFormControl(){
    return this.loginForm.get('password');
  }

  login() {
    if (this.loginForm.invalid) {
      // this.alertService.warn('Invalid credential', {title: 'Invalid'});
      return;
    }

    this.loading = true;
    const email = this.emailFormControl?.value;
    const password = this.passwordFormControl?.value;
    this.store
      .dispatch(
        new Login({ username: email, password })
      )
      .subscribe(
        async (result) => {
          this.loading = false;
          if (result.auth.token) {
            this.router.navigate([ApplicationPaths.DefaultLoginRedirectPath]);
          } else {
            this.loading = false;
            
          }
        },
        (err) => {
          this.loading = false;
          this.alertService.error(err.toString(), this.options);
        }
      );
  }

  navigateToReset(){
    this.router.navigate([this.applicationPaths.ResetPassword]);
  }

}
