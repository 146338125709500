import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { HttpClientModule } from '@angular/common/http';

import { environment } from 'src/environments/environment';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthState } from '../auth/state/auth.state';
import { SharedModule } from '../shared/shared.module';
import { ExportAsModule } from 'ngx-export-as';


@NgModule({
  declarations: [LayoutComponent, MainLayoutComponent, SidebarComponent, NavbarComponent,],
  imports: [
    NgxsModule.forRoot([AuthState], {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({disabled: environment.production}),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token', 'auth.username'],
    }),
    CommonModule,
    RouterModule,
    HttpClientModule,
    SharedModule,
    ExportAsModule
  ],
  exports: [
    LayoutComponent
  ],
})
export class CoreModule { }
