<nav id="sidebar">
  <div class="sidebar-header">
      <img src="../../../../assets/images/Logo.png">
  </div>

  <ul class="list-unstyled components">
      <li>
          <a [routerLink]="['']" routerLinkActive="router-link-active" [routerLinkActiveOptions]="{ exact: true }">
            <!-- Dashboard -->
            <button class="w-100 btn btn-lg btn-outline-purple d-flex justify-content-start">
                <span class ="espan">
                    <i class="bi bi-display eperson"></i>
                  </span>
                <span class="font-normal ml-4">Dashboard</span>
              </button>
        </a>
      </li>
      
      <li>
        <a [routerLink]="['/transactions']" routerLinkActive="router-link-active">
          <!-- Transaction History -->
          <button class="w-100 btn btn-lg btn-outline-purple d-flex justify-content-start">
              <span class ="espan">
                  <i class="bi bi-graph-up eperson"></i>
                </span>
              <span class="font-normal ml-4">Transaction History</span>
            </button>
      </a>
    </li>

    <li>
        <a [routerLink]="['/wallet']" routerLinkActive="router-link-active">
          <!-- Wallet -->
          <button class="w-100 btn btn-lg btn-outline-purple d-flex justify-content-start">
              <span class ="espan">
                  <i class="bi bi-wallet2 eperson"></i>
                </span>
              <span class="font-normal ml-4">Wallet</span>
            </button>
      </a>
    </li>

    <li>
        <a [routerLink]="['/payments']" routerLinkActive="router-link-active">
          <!-- Payment Log -->
          <button class="w-100 btn btn-lg btn-outline-purple d-flex justify-content-start">
              <span class ="espan">
                  <i class="bi bi-gear eperson"></i>
                </span>
              <span class="font-normal ml-4">Payment Log</span>
            </button>
      </a>
    </li>

    <li>
        <a [routerLink]="['/tracking']" routerLinkActive="router-link-active">
          <!-- Tracking -->
          <button class="w-100 btn btn-lg btn-outline-purple d-flex justify-content-start">
              <span class ="espan">
                  <i class="bi bi-geo-alt-fill eperson"></i>
                </span>
              <span class="font-normal ml-4">Tracking</span>
            </button>
      </a>
    </li>

    <li>
        <a [routerLink]="['/quotes']" routerLinkActive="router-link-active">
          <!-- Quick Quotes -->
          <button class="w-100 btn btn-lg btn-outline-purple d-flex justify-content-start">
              <span class ="espan">
                  <i class="bi bi-file-text eperson"></i>
                </span>
              <span class="font-normal ml-4">Quick Quotes</span>
            </button>
      </a>
    </li>
  </ul>
</nav>