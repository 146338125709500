import { AuthStateModel, Login, Logout } from './auth.actions';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { AuthService } from 'src/app/core/services/auth.service';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TokenModel } from 'src/app/core/models/token.model';
import { JwtHelper } from 'src/app/core/utilities/jwt.helper';
import { Keys } from 'src/app/core/constants/key.constants';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    token: null,
    username: null,
  },
})
@Injectable()
export class  AuthState {
  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }
  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean | null {
    return !JwtHelper.getokenData(state.token)?.IsExp;
  }
  @Selector()
  static tokenDetaail(state: AuthStateModel): TokenModel | null {
    return JwtHelper.getokenData(state.token);
  }
  @Selector()
  static emailAddress(state: AuthStateModel): string {
    return JwtHelper.getokenData(state.token)?.Email;
  }
  @Selector()
  static expiryDate(state: AuthStateModel): Date {
    return JwtHelper.getokenData(state.token)?.ExpireDate;
  }
  @Selector()
  static id(state: AuthStateModel): String {
    return JwtHelper.getokenData(state.token)?.Id;
  }

  constructor(private authService: AuthService) {}

  @Action(Login)
  login({ patchState }: StateContext<AuthStateModel>, { payload }: Login) {
    return this.authService.login(payload.username, payload.password).pipe(
      tap((result) => {
        localStorage.setItem(Keys.PROFILE, JSON.stringify(result.result.profile));
        if (result.result.token) {
          patchState({ token: result.result.token, username: payload.username });
        } else {
          patchState ({token: null, username: null});
        }
      }),
    );
  }

  @Action(Logout)
  logout({ setState }: StateContext<AuthStateModel>) {
    return this.authService.logout().pipe(
      tap((result)=> {
        localStorage.removeItem(Keys.PROFILE);
        return setState({ token: null, username: null });
      })
    )
    
  }
}
