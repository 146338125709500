import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';

import { AuthState } from './state/auth.state';
import { Store } from '@ngxs/store';
import {
  QueryParameterNames,
  ApplicationPaths,
  ApplicationPathsAccessControl,
} from '../core/constants/app.constants';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private store: Store, private router: Router) {}

  canActivate(_next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = this.store.selectSnapshot(
      AuthState.isAuthenticated
    );
    return this.handleAuthorization(isAuthenticated, state);
  }
  private handleAuthorization(
    isAuthenticated: boolean,
    state: RouterStateSnapshot
  ): boolean {
    if (!isAuthenticated) {
      this.router.navigate(ApplicationPaths.LoginPathComponents, {
        queryParams: {
          [QueryParameterNames.ReturnUrl]: state.url,
        },
      });
      return false;
    } else {
      return true;
    }
  }
}
