import { AbstractControl, FormControl, FormGroup } from '@angular/forms';

export class ValidatorHandlers {

    static passwordMatchValidator(frm: FormGroup) {
        return frm.controls.newPassword.value === frm.controls.confirmPassword.value ? null : {mismatch: true};
    }
}

export const EmailPattern = {
    emailRegex: '^\\w+[\\w-\\.\\+]*\\@\\w+((-\\w+)|(\\w*))\\.[a-z]{2,3}(\\.[a-z]{2,3})?$'
};
