const baseUrl = 'https://agofurelogistics.azurewebsites.net/api/CustomerPortal';

export const ItemNature = [
  { value: 'NORMAL', name: 'NORMAL' },
  { value: 'KEEP_UPRIGHT', name: 'KEEP_UPRIGHT'},
  { value: 'FRAGILE', name: 'FRAGILE'}
];

export const AuthActions = {
    Login: '/auth/login',
    ResetPassword: '/auth/reset-password',
    ResetCode: '/auth/reset-code'
  };

  export const StatusCodes = {
    success: 200,
    unauthenticated: 49,
    resourceAlreadyExist: 60,
    resourceNoLongerInuse: 65,
    noData: 69,
    noPermission: 79,
    badRequest: 89,
    serviceError: 89,
    failure: 11,
    badrequest: 88,
    invalidCredentials: 56,
  };

  export const AuthConstants = {
    authUrl: `${baseUrl}/login`,
    logoutUrl:`${baseUrl}/logout`,
    changePassword: `${baseUrl}/changepassword`,
    resetPassword: `${baseUrl}/resetpassword`,
    resetCode: `${baseUrl}/ValidateResetCodeandPassword`
  };

  export const PortalConstants = {
    activeterminal: `${baseUrl}/getactiveterminal`,
    createWalletLog: `${baseUrl}/addPaymentLog`,
    dashboard: `${baseUrl}/dashboard`,
    deliveryOptions: `${baseUrl}/deliveryoption`,
    fetchPaymentLog: `${baseUrl}/PaymentLog`,
    packageCategory: `${baseUrl}/getactivepackagecategory`,
    getQuickQuote: `${baseUrl}/QuickQuote`,
    shipments: `${baseUrl}/shipments`,
    trackShipment: `${baseUrl}/track`,
    getWaybill: `${baseUrl}/waybill`,
    walletTransaction: `${baseUrl}/walletTransaction`
  }

  export const ReturnUrlType = 'returnUrl';

  export const QueryParameterNames = {
    ReturnUrl: ReturnUrlType,
    Message: 'message',
  };

  interface ApplicationPathsType {
    readonly DefaultLoginRedirectPath: string;
    readonly Login: string;
    readonly ResetPassword: string;
    readonly ResetCode: string;
    readonly Dashboard: string;
    readonly Transactions: string;
    readonly Waybill: string;
    readonly Tracking: string;
    readonly Wallet: string;
    readonly Payments: string;
    readonly Quotes: string;
    readonly LoginPathComponents: string[];
    readonly ResetPasswordComponents: string[];
    readonly ResetCodeComponents: string[];
    readonly DashboardComponents: string[];
    readonly TransactionsComponents: string[];
    readonly WaybillComponents: string[];
    readonly TrackingComponents: string[];
    readonly WalletComponents: string[];
    readonly PaymentsComponents: string[];
    readonly QuotesComponents: string[];
  }
  let applicationPaths: ApplicationPathsType = {
    DefaultLoginRedirectPath: '',
    Login: `${AuthActions.Login}`,
    ResetPassword: `${AuthActions.ResetPassword}`,
    ResetCode: `${AuthActions.ResetCode}`,
    Dashboard: '',
    Transactions: 'transactions',
    Waybill: 'waybill',
    Tracking: 'tracking',
    Wallet: 'wallet',
    Payments: 'payments',
    Quotes: 'quotes',
    LoginPathComponents: [],
    ResetPasswordComponents: [],
    ResetCodeComponents: [],
    DashboardComponents: [],
    TransactionsComponents: [],
    WaybillComponents: [],
    TrackingComponents: [],
    WalletComponents: [],
    PaymentsComponents: [],
    QuotesComponents: [],
  };
  applicationPaths = {
    ...applicationPaths,
    LoginPathComponents: applicationPaths.Login.split('/'),
    ResetPasswordComponents: applicationPaths.ResetPassword.split('/'),
    ResetCodeComponents: applicationPaths.ResetPassword.split('/'),
    DashboardComponents: applicationPaths.Dashboard.split('/'),
    TransactionsComponents: applicationPaths.Transactions.split('/'),
    WaybillComponents: applicationPaths.Waybill.split('/'),
    TrackingComponents: applicationPaths.Tracking.split('/'),
    WalletComponents: applicationPaths.Wallet.split('/'),
    PaymentsComponents: applicationPaths.Payments.split('/'),
    QuotesComponents: applicationPaths.Quotes.split('/')
  };
  
  const applicationPathsAcessControl: ApplicationPathsType = {
    DefaultLoginRedirectPath: `all`,
    Login: `all`,
    ResetPassword: `all`,
    ResetCode: `all`,
    Dashboard: `all`,
    Transactions: `all`,
    Waybill: `all`,
    Tracking: `all`,
    Wallet: `all`,
    Payments: `all`,
    Quotes: `all`,
    LoginPathComponents: [],
    ResetPasswordComponents: [],
    ResetCodeComponents: [],
    DashboardComponents: [],
    TransactionsComponents: [],
    WaybillComponents: [],
    TrackingComponents: [],
    WalletComponents: [],
    PaymentsComponents: [],
    QuotesComponents: [],
  };
  export const ApplicationPaths: ApplicationPathsType = applicationPaths;
  export const ApplicationPathsAccessControl: ApplicationPathsType = applicationPathsAcessControl;
  
